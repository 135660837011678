.ui.container.pageContainer {
    min-width: 1127px;
    min-height: 500px;
    margin-top: 3.35em;
    padding: 2em;
    background: rgba(240, 240, 255, 0.9);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pageWrap {
  position: relative;
}
.pageWrap::after {
  content: "";
  background-image: linear-gradient(to bottom, rgba(41,150,193, 1), rgba(41,150,193, 0.2));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*opacity: 0.3;*/
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}