.rct-item {
  text-align: left;
  overflow: hidden;
}

.react-calendar-timeline .rct-horizontal-lines .row-talent {
  background: rgb(170, 255, 170, 0.5);
}

.react-calendar-timeline .rct-horizontal-lines .row-effect {
  /*background: rgba(136, 221, 136, 0.5);*/
}

.rct-item-content {
  text-shadow:
   -1px -1px 2px #000,  
    1px -1px 2px #000,
    -1px 1px 2px #000,
     1px 1px 2px #000;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 2000;
}

.react-calendar-timeline .rct-dateHeader span {
  width: 100%;
  margin: 0 1em;
  text-align: left;
}

.react-calendar-timeline .rct-header-root {
  background: #FFF;
}

.ui.grid.logs {
  max-height: 100px;
  overflow-y: auto;
  padding-bottom: 1em;
}

.ui.grid.logs .row {
  padding: 0;
  margin: 0 1em;
}

.ui.grid.logs .row.odd {
  background: #eee;
}

.ui.grid.logs .row>p{
  font-family: "Lucida Console";
  font-size: 0.8em;
}

.ui.grid.dmgsummary .column {
  padding:0 1em;
}

.ui.grid.dmgsummary {
  padding:1em 0;
}

.ui.header.logs {
  margin: 0.5em 0 1em 0;
}

a.ui.corner.label>.icon {
  cursor: inherit;
}

img.portrait {
  max-width:100px;
  max-height:100px;
}


.selection.dropdown.builds>.visible.menu.transition>.item {
    padding: .39rem .57rem!important;
}
.selection.dropdown.builds>.visible.menu.transition {
  min-width: calc(100% + 1px);
  width: calc(100% + 1px);
}
.selection.dropdown.builds>.icon.dropdown {
  padding: 0.458em;
}
.selection.dropdown.builds {
  width: calc(100% - 0.5em);
  padding: 0.38em 2.1em 0.38em 0.5em;
  min-height: 1.35em;
  margin-bottom: 0.5em;
}

.addcharacter {
  height: 100%;
  display: flex;
  cursor: pointer;
}
.addcharacter:hover {
  color: #666;
}
.ui.grid.teamGrid>.row>.column:not(.vs) {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
  