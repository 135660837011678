/*
.ui.form .inline.field label {
  width: 100px;
  text-align: right;
}*/

.ui.form .field>.selection.dropdown {
  width: calc(100% - 40px);
}
.ui.form .field>.ui.selection.dropdown.primaryStatPreset {
  width: 9em;
  min-width: 9em;
  margin-right: 1em;
}
.ui.form .field>.ui.selection.dropdown.subStatPreset {
  width: 12em;
  min-width: 12em;
  margin-right: 0.25em;
}

.ui.vertical.divider:after, .ui.vertical.divider:before {
  height: 100%;
}

.ui.search.selection.dropdown>input.search {
  padding-left: 3.78571429em;
}

.ui.icon.right.attached.button.undo {
  height: 67px;
  vertical-align: middle;
  padding-top: 1.8em;
}

.ui.selection.dropdown.character>.dropdown.icon,
.ui.selection.dropdown.weapon>.dropdown.icon {
  padding: 2.24em .78471429em;
}

.sub.header.rarity {
  background: 0 0!important;
  color: #ffa504!important;
  /*text-shadow: 0 -1px 0 #ddc507,-1px 0 0 #ddc507,0 1px 0 #ddc507,1px 0 0 #ddc507!important;*/
}

.ui.form .field.artifactStat {
  margin-bottom: 0.2em;
  width: 19em;
}
.ui.form .field.artifactStat>label {
  width: 9em;
}
.ui.form .field.artifactStat .ui.input.percent {
  width: 7.67142857em;
}
.ui.form .field.artifactStat .ui.dropdown {
  width: 7.67142857em;
  min-width: 7.67142857em;
}
.ui.form .field.artifactStat .ui.input.normal {
  width: 6em;
}

.artifactEdit .ui.tab {
  height: 364px;
}

.ui.input>input[type="range"] {
  padding:0;
}

.one.wide.column.vs {
  font-size: 100px;
  padding-top: 115px;
}

.ui.huge.rating {
  margin: .45em 0;
}

.ui.huge.rating>i.icon {
  width:20px;
}

.ui.active.visible.selection.dropdown.character .visible.menu.transition, 
.ui.active.visible.selection.dropdown.weapon .visible.menu.transition {
  max-height: 32rem;
}